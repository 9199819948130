import axios from "axios";

const eventsRequest = axios.create({
  baseURL: "https://api.stereocave.com/api",
  headers: {
    "Content-Type": "application/json",
  },
});

const get_all = async () => {
  try {
    const res = await eventsRequest.get("/events");
    return res.data.reverse();
  } catch (err) {
    return err.toString();
  }
};

export { get_all };
