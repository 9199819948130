import { useMemo } from "react";
import Cookies from "js-cookie";

function Thanks(props) {
  const info = useMemo(() => {
    const info = JSON.parse(Cookies.get("reservation_info") ?? "{}");
    return info;
  }, []);

  return (
    <div
      className="
        flex
        flex-col
        md:flex-row
        w-full
        justify-center
        flex-wrap
        overflow-y-auto
      "
    >
      {/* Middle */}
      <div
        className="
        px-6
        2xl:px-20
        flex-[1.4]
        "
      >
        <div
          className="
            mb-6
            md:my-12
            font-bold
            text-2xl
          "
        >
          Your Reservation
        </div>
        {/* Reservation Info */}
        <div
          className="
            md:max-w-md
            mx-auto
          "
        >
          <div
            className="
              text-left
              md:ml-10
              mt-16
              md:my-0
              break-all
              grid
              grid-cols-2
              md:gap-x-10
              gap-y-4
            "
          >
            <div className="">Event:</div>
            <div className="">{info.event?.title}</div>
            <div className="">Date:</div>
            <div className="">{info.event?.date?.slice(0, -3)}</div>
            <div className="">Seat Numbers:</div>
            <div className="">
              {info.seats?.map((seat_id, index, seats) => {
                const split = index + 1 === seats.length ? "" : ", ";
                return seat_id + split;
              })}
            </div>
            <div className="">Price:</div>
            <div className="">NT$ {info.event?.price * info.seats?.length}</div>
            <div className="">Name:</div>
            <div className="">{info.reserveInfo?.name}</div>
            <div className="">Phone:</div>
            <div className="">{info.reserveInfo?.phone}</div>
            <div className="">Email:</div>
            <div className="">{info.reserveInfo?.email}</div>
          </div>
        </div>
      </div>

      {/* Right (empty)*/}
      <div
        className="
          hidden
          md:flex
          p-6
          flex-[0.8]
          overflow-y-auto
          rounded
        "
      ></div>
    </div>
  );
}

export default Thanks;
