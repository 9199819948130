import axios from "axios";

const eventsRequest = axios.create({
  baseURL: "https://api.stereocave.com/api",
  headers: {
    "Content-Type": "application/json",
  },
});

const post = async(data, eventID) => {
  try {
    const res = await eventsRequest.post(`/events/${eventID}/reserve`, data);
    console.log(res.data);

    // try order api
    return res.data;
  } catch (err) {
    return err;
  }
};

const fetchResult = async(orderId) => {
  let success = false;
  let order;
  let result;

  while(!success) {
    try {
      console.log('still trying');
      const res = await eventsRequest.get(`/orders/${orderId}`);
      order = res.data.detail;
      success = order.paid;
      result = res.data;
    } catch (err) {
      console.error(err);
    }

    await new Promise(resolve => setTimeout(resolve, 1000));
  }

  return result;
}

export { post, fetchResult };
