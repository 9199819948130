import myContextContext from "components/createContext";
import * as reservationAPI from "api/Reservation";
import {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";

const Reservation = forwardRef((props, ref) => {
  const language = useContext(myContextContext);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const handleInputName = (e) => {
    setName(e.target.value);
    setError("");
  };

  const handleInputPhone = (e) => {
    setPhone(e.target.value);
    setError("");
  };

  const handleInputEmail = (e) => {
    setEmail(e.target.value);
    setError("");
  };

  useEffect(() => {
    const data = props.reserveInfo;
    setName(data.name);
    setPhone(data.phone);
    setEmail(data.email);
  }, []);

  useEffect(() => {
    props.setReserveData({
      name,
      phone,
      email,
    });
  }, [name, phone, email]);

  useImperativeHandle(ref, () => ({
    async submitReservation() {
      function isValid(data) {
        if (data.name === "") {
          setError("Name cannot be empty.");
        } else if (data.phone === "") {
          setError("Phone cannot be empty.");
        } else if (!/\S+@\S+\.\S+/.test(data.email)) {
          setError("Email is not valid.");
        } else {
          return true;
        }
      }

      const data = {
        seats: props.seats,
        name,
        phone,
        email,
      };
      console.log(data);

      if (isValid(data)) {
        const order = await reservationAPI.post(data, props.event.id);
        window.location.href = `https://api.stereocave.com/api/checkout/${order.detail.id}`;

        //window.open(
          //`https://api.stereocave.com/api/checkout/${order.detail.id}`,
          //"_blank"
        //);

        const result = await reservationAPI.fetchResult(order.detail.id);
        console.log("result: ", result);
        return result;
      }
    },
  }));

  return (
    <div
      className="
        flex
        flex-col
        md:flex-row
        w-full
      "
    >
      {/* Middle */}
      <div
        className="
          text-xl
          px-6
          2xl:px-20
          flex-[1.4]
        "
      >
        <div className="text-2xl mb-2">
          {language === "zh" && props.event.title}
          {language === "en" && props.event.title_en}
        </div>
        <div className="mb-2 text-grayDarker">
          {props.event.date.slice(0, -3)}
        </div>
        <div className="mb-2">
          {language === "zh" && "您的座位號："}
          {language === "en" && "Seat numbers: "}
          {props.seats?.map((seat_id, index, seats) => {
            const split = index + 1 === seats.length ? "" : ", ";
            return seat_id + split;
          })}
        </div>
        <div>
          {language === "zh" && "活動價格："}
          {language === "en" && "Price: "}
          NT${props.event.price * props.seats.length}
        </div>

        <div
          className="
            mt-6
            md:
            mx-auto
            text-left
            md:max-w-sm
            lg:max-w-md
          "
        >
          <label htmlFor="Name">
            {language === "zh" && "姓名："}
            {language === "en" && "Name:"}
          </label>
          <input
            id="Name"
            type="text"
            value={name}
            className="input mt-2 ml-1 w-full shadow-lg"
            onChange={handleInputName}
          />

          <label htmlFor="Phone">
            {language === "zh" && "電話："}
            {language === "en" && "Phone:"}
          </label>
          <input
            id="Phone"
            type="tel"
            value={phone}
            className="input mt-2 ml-1 w-full shadow-lg"
            onChange={handleInputPhone}
          />

          <label htmlFor="E-mail">
            {language === "zh" && "電子信箱："}
            {language === "en" && "E-mail:"}
          </label>
          <input
            id="E-mail"
            type="email"
            value={email}
            className="input mt-2 ml-1 w-full shadow-lg"
            onChange={handleInputEmail}
          />
          <div className="mt-8 text-center text-xl text-[#7B2200] h-8 w-full">
            {language === "zh" && "請允許彈出式視窗，並完成付款，以完成訂位"}
            {language === "en" &&
              "Please allow the pop-up window and complete the payment for the booking to be successful."}
          </div>

          <div className="mt-8 text-center text-xl text-[#7B2200] h-8 w-full">
            {error}
            <br />
            {props.errorMessage}
          </div>
        </div>
      </div>

      {/* Right */}
      <div
        className="
          p-6
          shadow-xl
          flex-[0.8]
          overflow-y-auto
          border-4
          border-gray
          border-double
        "
      >
        <p className="text-2xl mb-6">
          {language === "zh" && "服務條款"}
          {language === "en" && "Term of Service"}
        </p>
        {language === "zh" && (
          <ul className="list-none px-6">
            <li>請安靜</li>
            <li>請不要抽菸</li>
            <li>請關閉手機</li>
            <li>禁止拍照</li>
          </ul>
        )}

        {language === "en" && (
          <ul className="list-none px-3 text-sm">
            <li>PLEASE BE QUIET</li>
            <li>PLEASE NO SMOKING</li>
            <li>PLESE TURN OFF YOU PHONE</li>
            <li>PLEASE NO PHTOGRAPHING</li>
          </ul>
        )}

        {language === "zh" && (
          <p className="mt-10">
            為維護聆聽品質，遲到或中途離席觀眾恕不開放入場，敬請見諒。
          </p>
        )}

        {language === "en" && (
          <p className="mt-10 leading-tight">
            In order to maintain the quality of listening, audiences who are
            late or leave the venue will not be allowed to enter the venue,
            please understand us.
          </p>
        )}
      </div>
    </div>
  );
});

export default Reservation;
