import * as infoAPI from "api/Info";
import ReactHtmlParser from "react-html-parser";
import { useEffect, useState, useContext } from "react";
import myContextContext from "components/createContext";
import Icon from "@mdi/react";
import { mdiHandExtended } from "@mdi/js";
const publicURL = process.env.PUBLIC_URL;

function Info() {
  const language = useContext(myContextContext);
  const [about, setAbout] = useState("");
  const [philosophy, setPhilosophy] = useState("");
  const [showModal, setShowModal] = useState(false);
  // Get Events
  useEffect(() => {
    infoAPI.get().then((res) => {
      // FIXME: Error handling
      if (res !== "AxiosError: Network Error") {
        if (language === "zh") {
          setAbout(res.about);
          setPhilosophy(res.philosophy);
        } else if (language === "en") {
          setAbout(res.about_en);
          setPhilosophy(res.philosophy_en);
        }
      }
    });
  }, [language]);

  return (
    <div
      className="
        flex
        flex-col
        md:flex-row
        w-full
        justify-center
        flex-wrap
        overflow-y-auto
      "
    >
      {/* Middle */}
      <div
        className="
          text-xl
          md:flex-[1.4]
          shrink-0
          md:ml-6
          md:overflow-y-auto
        "
      >
        {/* About Info */}
        <div
          className="
            text-left
            text-sm
            md:text-base
            mt-6
            w-full
            overflow-x-hidden
            html_parser
            img_full
            max-w-sm
            lg:max-w-md
            mx-auto
          "
        >
          {ReactHtmlParser(about)}
        </div>
      </div>

      {/* Right */}
      <div
        className="
          md:flex-[0.8]
          overflow-y-auto
          px-6
        "
      >
        {/* Philosophy Modal Button */}
        <button
          className="
            min-w-[80%]
            text-lg
            p-8
            mt-[15vh]
            md:mt-[20vh]
            rounded-md
            shadow-xl
            font-Cinzel
            ease-linear
            transition-all
            duration-300
            border-double
            border-8
            border-gray
            rounded-none
          "
          type="button"
          onClick={() => setShowModal(true)}
        >
          {language === "zh" ? "我們的哲學" : null}
          {language === "en" ? "Our Philosophy" : null}
          <Icon
            path={mdiHandExtended}
            size={2}
            color="gray"
            className="mx-auto mt-2"
          />
        </button>

        {/* Philosophy Modal */}
        {showModal ? (
          <>
            <div
              className="
                justify-center
                items-center
                flex
                overflow-x-hidden
                overflow-y-auto
                fixed
                inset-0
                z-50
              "
            >
              <div
                className="
                  relative
                  w-auto
                  mx-2
                  my-6
                  mx-auto
                  max-w-3xl
                "
              >
                {/*content*/}
                <div
                  className="
                    bg-bgColor
                    border-0
                    rounded-lg
                    shadow-lg
                    relative
                    flex
                    flex-col
                    w-full
                  "
                  style={{
                    backgroundImage: `url('${publicURL}/img/grain.png')`,
                  }}
                >
                  {/*header*/}
                  <div className="flex justify-center p-5 border-b border-solid">
                    <h3 className="text-3xl font-semibold font-Cinzel">
                      Our Philosophy
                    </h3>
                  </div>
                  {/*body*/}
                  <div className="relative p-6 flex-auto">
                    <p className="my-4 text-left overflow-y-auto h-[50vh]">
                      {ReactHtmlParser(philosophy)}
                    </p>
                  </div>
                  {/*footer*/}
                  <div className="flex items-center justify-end p-6 border-t border-solid">
                    <button
                      className="font-bold px-6 py-2 mr-1 mb-1"
                      type="button"
                      onClick={() => setShowModal(false)}
                    >
                      I Accept
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-20 fixed inset-0 z-10 bg-black"></div>
          </>
        ) : null}
      </div>
    </div>
  );
}

export default Info;
