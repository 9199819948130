import ReactHtmlParser from "react-html-parser";
import { useContext, useEffect, useState } from "react";
import myContextContext from "components/createContext";
import * as eventAPI from "api/Event";

const publicURL = process.env.PUBLIC_URL;

function Event(props) {
  const language = useContext(myContextContext);
  const [events, setEvents] = useState([]);
  const [eventOptions, setEventOptions] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState({
    id: "",
    title: "",
    date: "",
    description: "",
  });

  // Initial, GET events
  useEffect(() => {
    eventAPI.get_all().then((res) => {
      // FIXME: Error handling
      if (res !== "AxiosError: Network Error") {
        setEvents(res);
      }
    });
  }, []);

  // Set already selected Event
  useEffect(() => {
    // Search Event with title
    function searchEvent(eventTitle) {
      const result = events.filter((event) => {
        return event.title === eventTitle;
      });
      return result[0];
    }
    // Set selected event
    const eventTitle = props.event.title;
    if (!!eventTitle) {
      setSelectedEvent(searchEvent(eventTitle));
    }
  }, [props.event.title, events]);

  // Set events options
  useEffect(() => {
    let title_data;
    // Check language
    if (language === "zh") title_data = "title";
    else if (language === "en") title_data = "title_en";
    // Set event options
    if (Array.isArray(events) && events.length > 0) {
      setEventOptions(
        events?.map((event) => (
          <option key={event.id} value={event.title}>
            {event[title_data]}
          </option>
        ))
      );
    }
  }, [events, language]);

  // Set style of current selected seats
  useEffect(() => {
    // Remove selected style
    const selected_seats = document.querySelectorAll(".seat-btn.selected-seat");
    if (!!selected_seats) {
      [].forEach.call(selected_seats, (s) => {
        s?.classList.remove("selected-seat");
      });
    }
    // Add selected style
    [].forEach.call(props.seats, (seat_id) => {
      const selected = document.getElementById(seat_id);
      if (!selected.classList.contains("reserved-seat")) {
        selected.classList.add("selected-seat");
      }
    });
    // console.log(props.seats);
  }, [props.seats]);

  // Set reserved seats, when selected event changed
  useEffect(() => {
    // Remove current reserved seats
    const curr_reseved = document.querySelectorAll(".seat-btn.reserved-seat");
    if (!!curr_reseved) {
      [].forEach.call(curr_reseved, (s) => {
        s?.classList.remove("reserved-seat");
      });
    }
    // Add reserved seats of selected event
    const reserved_seats = selectedEvent?.reservations?.map(
      (reservation) => reservation.seat
    );
    reserved_seats?.forEach((seat_id) => {
      const reserved = document.getElementById(seat_id);
      reserved.classList.remove("cursor-pointer");
      reserved.classList.add("reserved-seat");
    });
  }, [selectedEvent]);

  // Handle Seat data
  const handleClickSeat = (e) => {
    const seat = e.target;

    if (!seat.classList.contains("reserved-seat") && seat.id) {
      props.clickSeat(seat.id);
    }

    // Reset error message
    if (!!props.errorMessage.seatError) {
      props.errorMessage.seatError = "";
    }
  };

  // Search Event with title
  function searchEvent(eventTitle) {
    const result = events.filter((event) => {
      return event.title === eventTitle;
    });
    return result[0];
  }

  const handleSelectEvent = (e) => {
    const eventTitle = e.target.value;
    const event = searchEvent(eventTitle);
    // Set selected event
    if (event) {
      setSelectedEvent(event);
      props.selectEvent(event);
      // Reset error message
      if (!!props.errorMessage.eventError) {
        props.errorMessage.eventError = "";
      }
    }
    // Clear selected seats when event changed
    props.clearSeats();
  };

  // Return description with language setting
  function EventDescription() {
    let description_html;
    if (language === "zh") {
      description_html = ReactHtmlParser(selectedEvent?.description);
    } else if (language === "en") {
      description_html = ReactHtmlParser(selectedEvent?.description_en);
    } else description_html = "";
    return <div className="break-words">{description_html}</div>;
  }

  // Seats DOM
  let seats_view = [];
  for (let i = 1; i <= 15; i++) {
    seats_view.push(
      <div
        key={i}
        className="
          w-1/3
          px-5
          lg:px-8
          my-2
        "
      >
        <img
          id={i}
          className="seat-btn cursor-pointer mx-auto"
          src={`${publicURL}/img/icon/seat.png`}
          alt="available seat icon"
          onDragStart={(e) => e.preventDefault()}
          onClick={handleClickSeat}
        />
      </div>
    );
  }

  return (
    <div
      className="
        flex
        flex-col
        md:flex-row
        w-full
      "
    >
      {/* Left: Poster */}
      <div
        className="
          md:flex-[0_0_33.33%]
          pt-6
          pb-20
          md:py-0
        "
      >
        {!!selectedEvent?.poster ? (
          <img
            alt="event-poster"
            src={"https://api.stereocave.com/storage/" + selectedEvent?.poster}
            className="h-full object-contain mx-auto rounded"
          />
        ) : (
          <div
            className={`
              ${language === "zh" ? "text-base" : "text-xl"}
              md:py-[25vh]
              text-grayDarker
            `}
          >
            {language === "zh" ? "請選擇一個活動" : null}
            {language === "en" ? "Please select a event." : null}
          </div>
        )}
      </div>

      {/* Middle */}
      <div
        className="
          md:flex-[0_0_33.33%]
          flex
          flex-wrap
          justify-center
          md:overflow-y-auto
        "
      >
        {/* Seat Status */}
        <div
          className="
            flex
            justify-center
            self-center
            flex-wrap
            md:mx-[4vw]
            max-w-sm
            md:max-w-none
          "
        >
          {seats_view}
        </div>

        <div className="text-xl text-[#7B2200] h-10 w-full">
          {props.errorMessage.seatError}
        </div>

        {/* Icon Info*/}
        <div
          className={`
            ${language === "zh" ? "text-xs" : "text-base"}
            flex
            w-4/5
          `}
        >
          <div className="flex-1">
            <img
              className="mx-auto h-8"
              src={`${publicURL}/img/icon/seat.png`}
              alt="available seat icon"
            />
            <div className="pt-1">
              {language === "zh" ? "可選擇" : null}
              {language === "en" ? "available" : null}
            </div>
          </div>

          <div className="flex-1">
            <img
              className="mx-auto h-8 reserved-seat"
              src={`${publicURL}/img/icon/seat.png`}
              alt="reserved seat icon"
            />
            <div className="pt-1">
              {language === "zh" ? "已預定" : null}
              {language === "en" ? "reserved" : null}
            </div>
          </div>

          <div className="flex-1">
            <img
              className="mx-auto h-8 selected-seat"
              src={`${publicURL}/img/icon/seat.png`}
              alt="selected seat icon"
            />
            <div className="pt-1">
              {language === "zh" ? "已選擇" : null}
              {language === "en" ? "selected" : null}
            </div>
          </div>
        </div>
      </div>

      {/* Right: Select Event */}
      <div
        className="
          text-left
          p-2
          md:p-10
          img_full
          rounded
          md:shadow-2xl
          md:flex-[0_1_33.33%]
          justify-center
          order-first
          md:order-none
          overflow-x-hidden
          md:overflow-y-auto
        "
      >
        <select
          id="event_select"
          placeholder="Choose One"
          value={selectedEvent?.title}
          onChange={handleSelectEvent}
          className="
            w-2/3
            text-xl
            mb-3
            pb-1
            border-b-2
            border-gray
            bg-bgColor
            focus-visible:outline-none
          "
          style={{ backgroundImage: `url('${publicURL}/img/grain.png')` }}
        >
          <option value="" disabled defaultValue>
            {language === "zh" ? "選擇活動場次" : null}
            {language === "en" ? "Choose a event..." : null}
          </option>
          {eventOptions}
        </select>

        <div className="inline-block w-1/3 text-right">
          NT$ {selectedEvent?.price}
        </div>

        <div className="text-xl text-[#7B2200] h-8 w-full">
          {props.errorMessage.eventError}
        </div>

        <div className="mb-6">{selectedEvent?.date}</div>

        <EventDescription />
      </div>
    </div>
  );
}
export default Event;
