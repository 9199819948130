import { useEffect, useRef, useState } from "react";
import myContextContext from "components/createContext";
import { useSearchParams } from "react-router-dom";
import Cookies from "js-cookie";

import Info from "components/Info";
import Event from "components/Event";
import Reservation from "components/Reservation";
import Thanks from "components/Thanks";

import ParticleBackground from "components/ParticleBackground";
import Icon from "@mdi/react";
import {
  mdiOpenInNew,
  mdiChevronLeft,
  mdiChevronRight,
  mdiArrowULeftTop,
  mdiInstagram,
  mdiFacebook,
  mdiVolumeHigh,
  mdiVolumeOff,
} from "@mdi/js";

const publicURL = process.env.PUBLIC_URL;

function App() {
  const ref = useRef();
  const processAmount = 4;
  const [process, setProcess] = useState(1);
  const [language, setLanguage] = useState("zh");
  const [seats, setSeats] = useState([]);

  const [event, setEvent] = useState({
    title: "",
    date: "",
    price: "",
    description: "",
  });

  const [reserveInfo, setReserveIndfo] = useState({
    name: "",
    phone: "",
    email: "",
  });

  const [errorMessage, setErrorMessage] = useState({
    seatError: "",
    eventError: "",
  });

  // handle proccess passing form url
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    const process = Number(searchParams.get("process") ?? 1);
    if (process === 1 || process === 4) setProcess(process);
    setProcess(process);
  }, [searchParams]);

  // Add or remove seat when click
  const clickSeat = (seat_id) => {
    if (seats.includes(seat_id)) {
      // remove seat_id
      setSeats(seats.filter((item) => item !== seat_id));
    } else {
      // add seat_id
      setSeats([...seats, seat_id]);
    }
  };

  // Sort seat_id data
  useEffect(() => {
    setSeats(
      seats.sort(function (a, b) {
        return a - b;
      }),
    );
  }, [seats]);

  const clearSeats = () => {
    setSeats([]);
  };

  const selectEvent = (event) => {
    setEvent(event);
  };

  const setReserveData = (data) => {
    setReserveIndfo(data);
  };

  // Scroll to top
  const topRef = useRef();
  const scrollToTop = () => topRef.current.scrollIntoView();

  const handlePrevious = () => {
    if (process > 1) setProcess(process - 1);
    setErrorMessage("");
    scrollToTop();
  };

  // handle music file
  // const audio = new Audio(`${publicURL}/audio/loop.mp3`);
  const audio = useRef(null);
  const [audioPlaying, setAudioPlaying] = useState(false);
  const handleAudioPlay = () => {
    if (!audioPlaying) {
      audio.current.play();
      setAudioPlaying(true);
    }
  };
  const handleAudioSwitch = () => {
    setAudioPlaying((prevState) => !prevState);
  };
  const handleAudioEnded = () => {
    setAudioPlaying(false);
  };

  useEffect(() => {
    audio.current.volume = 0.6;

    if (audioPlaying) {
      audio.current.play();
    } else {
      audio.current.pause();
    }
  }, [audioPlaying, audio]);

  // Control whether going to next page, and play audio
  const handleNext = async () => {
    handleAudioPlay();

    if (process === 2) {
      // Event Page
      if (event.title === "") {
        setErrorMessage({
          eventError: "Please select a event.",
        });
      } else if (seats.length === 0) {
        setErrorMessage({
          seatError: "Please select a seat.",
        });
      } else {
        setProcess(process + 1);
        setErrorMessage("");
      }
    } else if (process === 3) {
      // Reservation Page
      Cookies.set(
        "reservation_info",

        JSON.stringify({
          event: {
            title: event.title,
            date: event.date,
            price: event.price,
          },

          seats: seats,

          reserveInfo: {
            name: reserveInfo.name,
            phone: reserveInfo.phone,
            email: reserveInfo.email,
          },
        }),
      );

      const result = await ref.current.submitReservation();

      if (result?.message === "Reservation Succeed") {
        setProcess(process + 1);
        setErrorMessage("");
      } else {
        setErrorMessage(result.response.data.message);
      }
    } else if (process < processAmount) {
      setProcess(process + 1);
      setErrorMessage("");
    }
    scrollToTop();
  };

  // Reset data when back to home page
  const handleReset = () => {
    setProcess(1);
    setSeats([]);
    setEvent({
      title: "",
      date: "",
      description: "",
    });
    setErrorMessage("");
    setSearchParams({ process: 1 });
  };

  return (
    <div
      ref={topRef}
      className="
        text-center
        min-h-[100vh]
        overflow-y-auto
      "
    >
      {/* Music */}
      <audio
        ref={audio}
        onEnded={handleAudioEnded}
        src={`${publicURL}/audio/loop.mp3`}
      />

      {/* Header */}
      <div
        className={`
          py-4
          md:py-0
          md:h-[13vh]
          text-[5vh]
          font-bold
          flex
          justify-center
        `}
      >
        {/* Title */}
        <div
          className="
            self-center
            font-Cinzel
          "
        >
          {process === 1 ? "About" : null}
          {process === 2 && (
            <div className="text-[4vh] md:text-[5vh] leading-[4vh]">
              Ticket <br className="md:hidden" /> Booking
            </div>
          )}
          {process === 3 && (
            <div className="text-[4vh] md:text-[5vh]">Checkout</div>
          )}
          {process === 4 && (
            <div
              className="
                italic
                text-[3vh]
                md:text-[4vh]
              "
            >
              Thank you for <br className="md:hidden" />
              support us.
            </div>
          )}
        </div>

        {/* Language */}
        <div
          className={`
            ${process === 4 ? "hidden" : null}
            absolute
            top-0
            right-0
            mr-4
            md:mr-12
          `}
        >
          <select
            onChange={(e) => setLanguage(e.target.value)}
            className="
              text-sm text-center
              rounded p-1
              bg-grayLight
              border-gray
              focus-visible:outline-none
            "
          >
            <option value="zh">中文</option>
            <option value="en">English</option>
          </select>
        </div>
      </div>

      <ParticleBackground />

      {/* Content */}
      <div
        className="
          md:min-h-[87vh]
          md:max-h-[87vh]
          flex
          flex-col
          md:flex-row
          px-6
          md:px-10
          lg:px-16
        "
      >
        {/* Left Info */}
        {process === 2 ? null : (
          <div
            className={`
              ${process === 1 ? "flex" : "hidden"}
              md:flex
              md:flex-[0.8]
              flex-wrap
              justify-center
            `}
          >
            {/* 穴 */}
            <div
              className="
                py-10
                md:pt-0
                w-full
                flex
                flex-col
                justify-center
                self-start
                text-[25vh]
                leading-[20vh]
              "
            >
              <div className="text-cave ">穴</div>
              <div className="-mt-[10vh] mb-6">🕳️</div>
            </div>

            {/* Access */}
            <div
              className="
                px-[3vw]
                pb-10
                w-full
                self-end
                text-sm
              "
            >
              {/*}
              <p
                className="
                  font-bold
                  font-Cinzel
                  text-lg
                  mb-1
                "
              >
                Access
              </p>
              <p
                className="
                  max-w-xs
                  mx-auto
                  pb-6
                  text-grayDarker
                  text-left
                "
              >
                &emsp; Access at the terminal of Lushan Hot Spring, walk down
                for 3 minutes and you could find the house with red door
                opposite Minlu Hotel. Please make an reservation before you want
                to visit.
              </p>
              {*/}

              {/* 穴 あな ANA */}
              <div className="mt-6 md:pt-0">
                <b>穴 あな ANA</b>
              </div>

              {/* Instagram & Facebook link */}
              <div className="flex justify-center my-1 text-black">
                <a
                  href="https://www.instagram.com/stereocave/"
                  target="_blank"
                  rel="noreferrer"
                  className="
                    rounded-full w-6 h-6 mx-1.5
                    flex items-center justify-center
                    bg-gray hover:text-grayLight"
                >
                  <Icon
                    className="inline-block"
                    path={mdiInstagram}
                    size={0.7}
                  />
                </a>

                <a
                  href="https://www.facebook.com/profile.php?id=100088975745137"
                  target="_blank"
                  rel="noreferrer"
                  className="
                    rounded-full w-6 h-6 mx-1.5
                    flex items-center justify-center
                    bg-gray hover:text-grayLight"
                >
                  <Icon
                    className="inline-block"
                    path={mdiFacebook}
                    size={0.7}
                  />
                </a>
              </div>

              {/* Google Map */}
              {/*}
              <a
                className="
                  hover:text-black
                  text-grayDarker
                  font-Cinzel
                  font-bold
                  underline
                "
                href="https://www.google.com/maps?q=%E7%A9%B4%E2%80%A2%E3%81%82%E3%81%AA,+No.+17%E4%B9%8B1%E8%99%9F%E6%A6%AE%E8%8F%AF%E5%B7%B7+Ren%E2%80%99ai+Township,+Nantou+County,+546&ftid=0x3468c1092c703179:0xc9c608cb6f523404&hl=ru-TW&gl=tw&entry=gps&g_ep=CAIYAA%3D%3D&shorturl=1"
                target="_blank"
                rel="noreferrer"
              >
                Google Map
                <Icon
                  className="inline pl-1"
                  path={mdiOpenInNew}
                  title="Link"
                  size={0.8}
                />
              </a>
              {*/}
            </div>
          </div>
        )}

        {/* Middle & Right */}
        <div className="md:flex-[2.2]">
          {/* Components Per Page */}
          <div
            className="
              pt-10
              md:pt-0
              md:min-h-[73vh]
              flex
            "
          >
            <myContextContext.Provider value={language}>
              {process === 1 ? <Info /> : null}

              {process === 2 && (
                <Event
                  event={event}
                  seats={seats}
                  clickSeat={clickSeat}
                  clearSeats={clearSeats}
                  selectEvent={selectEvent}
                  errorMessage={errorMessage}
                />
              )}

              {process === 3 && (
                <Reservation
                  event={event}
                  seats={seats}
                  ref={ref}
                  reserveInfo={reserveInfo}
                  setReserveData={setReserveData}
                  errorMessage={errorMessage}
                />
              )}

              {process === 4 && (
                <Thanks seats={seats} event={event} reserveInfo={reserveInfo} />
              )}
            </myContextContext.Provider>
          </div>

          {/* Progress Control */}
          <div
            className="
              text-gray
              text-lg
              pt-20 pb-6
              md:pt-0
              md:h-[12vh]
              font-bold
              font-Cinzel
              flex
              justify-end
              items-end
            "
          >
            <button onClick={handleAudioSwitch}>
              <Icon
                className="hover:cursor-pointer hover:text-black"
                path={audioPlaying ? mdiVolumeHigh : mdiVolumeOff}
                size={1.1}
              />
            </button>

            {/* Previous Button */}
            {process > 1 && process < processAmount && (
              <button
                onClick={handlePrevious}
                className="
                  pr-2 mx-1
                  hover:text-grayDarker
                "
              >
                <Icon className="inline pl-1" path={mdiChevronLeft} size={1} />
                previous
              </button>
            )}

            {/* Slide */}
            {process > 1 && process < processAmount && (
              <div className="font-bold text-xl">|</div>
            )}

            {/* Next Button */}
            {process < processAmount && (
              <button
                onClick={handleNext}
                className="
                  mx-1 pl-2
                  hover:text-grayDarker"
              >
                {process === 3 ? "confirm" : "next"}
                <Icon className="inline pl-1" path={mdiChevronRight} size={1} />
              </button>
            )}

            {/* Home Button */}
            {process === processAmount && (
              <button
                onClick={handleReset}
                className="
                  mx-1
                  pl-2
                  hover:text-grayDarker
                "
              >
                back to home page
                <Icon
                  className="inline pl-1"
                  path={mdiArrowULeftTop}
                  size={1}
                />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
